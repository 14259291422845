import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {FilmContextSection} from '../../Components/FilmContextSection';
import {FilmProperty} from '../../Components/FilmProperty';
import {
  AccessTime,
  CalendarToday,
  LocalMovies,
  LocationOn,
} from '@styled-icons/material';
import moment from 'moment';
import {FilmIntro} from '../../Components/FilmIntro';
import {useOrder} from '../../Hooks/useOrder';
import Spinner from '../../Components/Spinner';
import QRCodeWrapper from '../../images/temp/qrcode.png';
import QRCode from 'qrcode.react';
import {FlexColumnCenterStart} from '../../widget';
import Carousel from 'react-slick';
import {NextArrow, PrevArrow} from '../../Components/Arrows';
import {useVistaOrder} from '../FilmOrder';
import {getOrderSeatsInfo} from '../../Utils/Ticket';

const OrderCheck = (props) => {
  const {location} = props;
  const {id} = qs.parse(location.search);
  const {isLoading, order} = useOrder(id);
  const targetSession = order?.sessions?.[0];
  const {isVistaOrderLoading, vistaOrder} = useVistaOrder(order);
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  if (isLoading || isVistaOrderLoading) {
    return <Spinner />;
  }
  return (
    <Wrapper>
      <div className="view">
        <FilmIntro>
          <img
            src={targetSession.film.poster}
            alt="film"
            onError={(e) => {
              e.target.src =
                'https://fakeimg.pl/282x400/?text=404&font=lobster';
            }}
          />
          <FilmContextSection
            film={{
              caption: {
                title: targetSession.film.titleAlt,
                subtitle: targetSession.film.title,
              },
              rating: targetSession.film.rating,
            }}>
            <div className="row">
              <FilmProperty
                style={{marginRight: 5}}
                value={`${targetSession.film.runTime}分鐘`}>
                <AccessTime size={12} />
              </FilmProperty>
              <FilmProperty value={'3D'}>
                <LocalMovies size={12} />
              </FilmProperty>
            </div>
            <FilmProperty value={targetSession.film.cinemaName}>
              <LocationOn size={12} />
            </FilmProperty>
            <FilmProperty
              value={moment(targetSession.showDateTime).format(
                'YYYY/MM/DD HH:MM',
              )}>
              <CalendarToday size={12} />
            </FilmProperty>
            <FilmProperty
              value={`第${targetSession.film.screenNumber}廳
                      ${getOrderSeatsInfo(targetSession.tickets)}`}>
              <LocationOn size={12} />
            </FilmProperty>
          </FilmContextSection>
        </FilmIntro>

        <Carousel {...settings}>
          {vistaOrder.Booking.Tickets.map((t, i) => (
            <div key={i} style={{display: 'flex', flexDirection: 'column'}}>
              <div className="qrcode-item" key={i}>
                <div className="qrcode-wrapper">
                  {/*//TODO: 等到驗票後在顯示*/}
                  {false && (
                    <div className="qrcode-filter">
                      <div className="badge">已驗票</div>
                    </div>
                  )}
                  <QRCode
                    className="qrcode"
                    value={`${vistaOrder.Booking.VistaTransactionId}/${t.TicketSequenceNumber}`}
                  />
                </div>
              </div>
              <div style={{textAlign: 'center'}}>
                {`${vistaOrder.Booking.VistaTransactionId}/${t.TicketSequenceNumber}`}
              </div>
            </div>
          ))}
        </Carousel>
        <section className="hint">
          請於電影開演前30分鐘進行線上退款，若超過線上退票規定之時間，請持原消費信用卡與原訂票手機，於電影開演前20分鐘，至購票影城辦理退款。
        </section>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));

  .view {
    ${FlexColumnCenterStart};

    .slick-slider {
      width: 100%;
      margin-bottom: 10px;

      .qrcode-item {
        display: flex !important;
        position: relative;
        align-items: center;
        justify-content: center;
        & > .qrcode-wrapper {
          width: 220px;
          height: 400px;
          background-image: url(${QRCodeWrapper});
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: relative;

          & > .qrcode {
            position: absolute;
            top: 142px;
            left: 46px;
          }
          & > .qrcode-filter {
            top: 20px;
            bottom: 20px;
            left: 0;
            right: 0;
            position: absolute;
            background-color: rgba(20, 20, 20, 0.7);
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            & > .badge {
              padding: 10px;
              background-color: #fff;
              border-radius: 10px;
            }
          }
        }
      }

      & button.circle.left {
        z-index: 10;
        left: 0;
      }

      & button.circle.right {
        z-index: 10;
        right: 0;
      }
    }

    & section.hint {
      text-align: center;
      padding: 0 20px;
      font-size: 10px;
      line-height: 1.57;
      margin: 20px 0;
    }
  }
`;

export default OrderCheck;
