import {ChevronLeft, ChevronRight} from '@styled-icons/material';
import React from 'react';
import styled from 'styled-components';
import {Color} from '../constants';

export const PrevArrow = (props) => {
  const {style = {}, onClick} = props;
  return (
    <StyledButton onClick={onClick} style={style} className="circle left">
      <ChevronLeft />
    </StyledButton>
  );
};

export const NextArrow = (props) => {
  const {style = {}, onClick} = props;
  return (
    <StyledButton onClick={onClick} style={style} className="circle right">
      <ChevronRight />
    </StyledButton>
  );
};

const StyledButton = styled.button`
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: ${Color.white};
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 10px ${Color.black_10};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 200ms linear;
  opacity: 0.7;

  & > svg {
    fill: ${Color.orange};
    font-weight: bold;
  }

  &:hover {
    transform: translateY(-50%) scale(1.5);
    opacity: 1;
  }

  &:active {
    transform: translateY(-50%) scale(1.5);
    opacity: 1;
  }
`;
